import { fb, validators } from '../../lib/form';

export function getModel(data) {

  const model = fb.group({
    ngrNumber: [data.ngrNumber || '', [validators.Required()], { label: 'NGR number', type: 'text' }],
    tradingName: [data.tradingName || '', [validators.Required()], { label: 'Trading name', type: 'text' }],
    contactPerson: [data.contactPerson || '', [validators.Required()], { label: 'Contact name', type: 'text' }],
    paymentDate: [data.paymentDate || '', [], { label: 'Payment date', type: 'text' }],
    paymentAmount: [data.paymentAmount  || '', [], { label: 'Payment amount ', type: 'text' }],
    email: [data.email || '', [validators.Required(), validators.Email()], { label: 'Email address', type: 'email' }],

    paymentNumber: [data.paymentNumber || '', [], { label: 'Payment/invoice number', type: 'text' }],

  });

  return model;
}

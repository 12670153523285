import React from "react";
import env from "../../env";
import { useForm } from "../../form";
// import { SiteLink} from '../../components';
import { usePost } from "../../components";
import { Button, ErrorMessage } from "../../components";
import { getModel } from "./formModel";

export function PriceUpdate() {
  const model = getModel({});
  const form = useForm(model);
  const [sendingStatus, setSendingStatus] = React.useState(null);
  const post = usePost();

  function onSubmit(e) {
    form.validateForm(e, () => {
      
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute("6LfUxxEqAAAAABuYhqccSJLsxrDZS-WPrAksq3u3", {
            action: "submit",
          })
          .then((token) => {
            const recaptchaValue = token;
            const value = {
              ...form.getValue(),
              recaptchaValue: recaptchaValue,
            };

            post.send(env.apiBase + "/api/form/priceupdate", value);
            setSendingStatus("pending");
          });
      });

    });
  }

  if (post.done() && sendingStatus === "pending") {
    form.reset(model);
    setSendingStatus(null);
  }

  const render = (name) => form.renderControl(name, null);

  return (
    <form className="editor">
      <div className="row">
        <div className="col-md-6">{render("ngrNumber")}</div>
      </div>
      <div className="row">
        <div className="col-md-6">{render("tradingName")}</div>
      </div>
      <div className="row">
        <div className="col-md-6">{render("contactPerson")}</div>
      </div>
      <div className="row">
        <div className="col-md-6">{render("phone")}</div>
      </div>
      <div className="row">
        <div className="col-12">{render("products")}</div>
      </div>
      <div className="row">
        <div className="col-md-6">{render("productOther")}</div>
      </div>
      <div className="row">
        <div className="col-12">{render("deliveryTerminal")}</div>
      </div>

      <h4 style={{ margin: "1rem 0 0", fontSize: "1.1rem" }}>SMS Service</h4>
      <div className="row">
        <div className="col-md-6">
          {render("smsService")}
          {render("mobile")}
        </div>
      </div>
      {/* <h4 style={{margin: '1rem 0 0', fontSize: '1.1rem'}}>Fax Service</h4>
      <div className="row">
        <div className="col-md-6">{render('faxService')}
        {render('fax')}
        </div>
      </div> */}
      <h4 style={{ margin: "1rem 0 0", fontSize: "1.1rem" }}>Email Service</h4>
      <div className="row">
        <div className="col-md-6">
          {render("emailService")}
          {render("email")}
        </div>
      </div>

      <div className="form-row">
        <div className="col-md-12 mb-2">
          <div className="actions">
            <Button onClick={onSubmit} status={post.status}>
              SUBMIT
            </Button>
            <ErrorMessage
              errors={form.errors}
              summaryMessage="Please review the errors."
            />
            <ErrorMessage errors={post.errors} />
            {post.done() && (
              <span style={{ color: "chocolate", marginLeft: "1rem" }}>
                Successfully Sent!
              </span>
            )}
          </div>
        </div>
      </div>
    </form>
  );
}

import { fb, validators } from '../../../lib/form';

export function getModel(data) {

  const teams = [{ name: "Select...", value: "" }, { name: "Adelaide Crows", value: "Adelaide Crows" }, { name: "Brisbane Lions", value: "Brisbane Lions" }, { name: "Carlton", value: "Carlton" }, { name: "Collingwood", value: "Collingwood" }, { name: "Essendon", value: "Essendon" }, { name: "Fremantle", value: "Fremantle" }, { name: "Geelong Cats", value: "Geelong Cats" }, { name: "Gold Coast Suns", value: "Gold Coast Suns" }, { name: "GWS GIANTS", value: "GWS GIANTS" }, { name: "Hawthorn", value: "Hawthorn" }, { name: "Melbourne", value: "Melbourne" }, { name: "North Melbourne", value: "North Melbourne" }, { name: "Port Adelaide", value: "Port Adelaide" }, { name: "Richmond", value: "Richmond" }, { name: "St Kilda", value: "St Kilda" }, { name: "Sydney Swans", value: "Sydney Swans" }, { name: "West Coast Eagles", value: "West Coast Eagles" }, { name: "Western Bulldogs", value: "Western Bulldogs" }]
  const model = fb.group({
    username: [data.username || '', [validators.Required()], { label: 'Username', type: 'text' }],
    password: [data.password || '', [validators.Required(), validators.Length('Min 7 characters', {min: 7})], { label: 'Password', type: 'password' }],
    confirmPassword: [data.confirmPassword || '', [validators.Required()], { label: 'Confirm Password', type: 'password' }],
    email: [data.email || '', [validators.Required(), validators.Email()], { label: 'Email', type: 'email' }],

    firstName: [data.firstName || '', [validators.Required()], { label: 'First Name', type: 'text' }],
    surname: [data.surname || '', [validators.Required()], { label: 'Surname', type: 'text' }],
    address: [data.address || '', [], { label: 'Address', type: 'text' }],
    address2: [data.address2 || '', [], { label: null, type: 'text' }],
    suburb: [data.suburb || '', [], { label: 'City, Suburb or Town', type: 'text' }],
    state: [data.state || '', [], { label: 'State', type: 'text' }],
    phone: [data.phone || '', [], { label: 'Phone', type: 'text' }],
    mobile: [data.mobile || '', [], { label: 'Mobile Phone', type: 'text' }],

    favoriteTeam: [data.favoriteTeam || '', [], { label: 'Favorite Team', type: 'select', options: teams }],
    favoriteLocalTeam: [data.favoriteLocalTeam || '', [], { label: 'Favorite Local Team', type: 'text' }],
    tradingName: [data.tradingName || '', [], { label: 'Trading Name', type: 'text' }],
    ngrNumber: [data.ngrNumber || '', data.compId === 13 || data.compId === 14? [] : [validators.Required()], { label: 'NGR number', type: 'text' }],

    nominatingFarmer: [data.nominatingFarmer || '', [], { label: 'Nominating Farmer', type: 'text' }],
    teamName: [data.teamName || '', [], { label: 'Team Name', type: 'text' }],
    abn: [data.abn || '', [], { label: 'ABN', type: 'text' }],
    
  });

  return model;
}
import React from 'react';
import env from '../../../env';
import { useForm } from '../../../form';
// import { SiteLink} from '../../components';
import { usePost } from '../../../components';
import { Button, ErrorMessage } from '../../../components';
import { getModel } from './formModel';
import { TippingMenu } from '../menu';
import { getSession } from '../tippingCommon';

export function TippingChangePassword() {

  // /const { navigate } = useRouter();
  const model = getModel({});
  const form = useForm(model);
  const [sendingStatus, setSendingStatus] = React.useState(null);
  const post = usePost();

  const sessionKey = getSession();
  function onSubmit(e) {
    form.validateForm(e, () => {
      const value = { ...form.getValue(), sessionToken: sessionKey };
      console.log(value);
      post.send(env.apiBase + "/api/tipping/changePassword", value);
      setSendingStatus('pending')
    })
    console.log('submitting')
  }

  if (post.done() && sendingStatus === 'pending') {
    form.reset(model)
    setSendingStatus(null)
  }

  const render = (name) => form.renderControl(name, null);

  return (
    <div>
      <TippingMenu current="changePassword" />
      <form className="editor">
        <div className="row">
          <div className="col-md-6">{render('currentPassword')}</div>
        </div>
        <div className="row">
          <div className="col-md-6">{render('password')}
            <div className="help">Minimum 7 characters</div>
          </div>
          <div className="col-md-6">{render('confirmPassword')}</div>
        </div>
        <div className="form-row">
          <div className="col-md-12 mb-2">
            <div className="actions">
              <Button onClick={onSubmit} status={post.status}>CHANGE PASSWORD</Button>
              <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
              <ErrorMessage errors={post.errors} />
              {post.done() && <span style={{ color: 'chocolate', marginLeft: '1rem' }}>Successfully Changed!</span>}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
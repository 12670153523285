import { css } from '@emotion/core'
import { variables, mq } from '../../../cssInJs'

const team = css(mq({
  width: '40%',
  display: 'flex',
  flexDirection: ['column',null, 'row'],
  alignItems: [null, null, 'center'],
  justifyContent: 'space-between',
}))

const teamIcon = css(mq({
  width: [50, 50, 80],
  marginRight: '1rem',
}))

const enterInput = css(mq({
  flex: ['0 1 auto', null, '1 1 auto'],
  'input': {
    marginRight: 4
  }
}))

export default {
  roundHeader: css(mq({
    display: 'flex',
    flexWrap: ['wrap', null, 'nowrap']
  })),
  roundHeaderCell: css(mq({
    flex: ['0 0 2rem', null, '1 0 auto'],
    display: 'inline-block',
    background: '#eee',
    cursor: 'pointer',
    padding: ['0.2rem'],
    textAlign: 'center',
    marginRight: '1px',
    marginBottom: '1px',
  })),
  roundHeaderCellCurrent: css({
    background: variables.primaryColor,
    color: 'white',
  }),
  day: css({
    backgroundColor: variables.primaryColor,
    color: '#fff',
    padding: '.3em .8em',
    fontSize: '1.1rem',
    fontWeight: 'bold',
    marginTop: '0.5em',
  }),
  venue: css({
    fontSize: '0.8rem',
    color: '#949494',
    textTransform: 'uppercase',
    borderBottom: 'solid 1px #ddd',
    padding: '0.1rem 0.3rem',
  }),
  game: css({
    background: 'white',
    padding: '0.5rem',
    margin: '5px 0',
    '&:nth-of-type(2n)': {
      background: '#f6f6f6'
    }
  }),
  teams: css(mq({
    display: 'flex',
    alignItems: [null, null, 'center'],
    padding: '0.5rem',
    justifyContent: 'space-between',
  })),
  home: team,
  homeIcon: teamIcon,
  homeInput: enterInput,
  away: team,
  awayIcon: teamIcon,
  awayInput: enterInput,
  draw: css(team, {
    width: '4rem'
  }),
  drawInput: enterInput,

  teamName: css({
    fontWeight: 'bold',
  }),
  teamHelp: css(mq({
    display: ['block', null, null, 'inline'],
    marginLeft: ['1rem', null, null, 0],
    fontSize: '0.75rem',
    fontWeight: 'normal'
  }))

}
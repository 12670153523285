import React from 'react';
import env from '../../../env';
import { usePost } from '../../../components';
import { Loading, ErrorPopup } from '../../../components';
import { getSession } from '../tippingCommon';
import './tippingText.scss';

import { useRouter } from '../../../components';
import { handleElementLink } from '../../../components';

export function TippingText({compCode, textType}) {
  
  const [sendingStatus, setSendingStatus] = React.useState(null);
  const [text, setText] = React.useState('');
  const post = usePost();
  const { history } = useRouter();

  const sessionKey = getSession();

  React.useEffect(() => {
    //console.log('useEffect',currentRoundId)
    post.send(env.apiBase + "/api/tipping/getText", {sessionToken: sessionKey, compCode: compCode, textType: textType });
    setSendingStatus('pending')
    // eslint-disable-next-line
  }, []);

  if (post.loading()) {
    return <Loading />
  } else if (post.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={post.errors} />
  }

  if (post.done() && sendingStatus === 'pending') {
    setSendingStatus(null)
    const data = post.response;
    setText(data.results.response.text);
  }

  
  function handleClick(e) { 
    
    let target = "";
    if(e.target.closest('a')){
      target = e.target.closest('a').getAttribute('target');
    }
    const processed = (target === "_blank" ? handleElementLink(e.target, null) : handleElementLink(e.target));
    //const link = utils.url.getLinkFromElement(e.target);
    //console.log('clicked', e, e.target, processed);
    
    if (processed.handled) {
      e.preventDefault();
      e.stopPropagation();
    } else if (processed.linkInfo && processed.linkInfo.type === 'internal') {
      e.preventDefault();
      e.stopPropagation();
      history.push(processed.linkInfo.url)
    } else {
      // e.preventDefault();
      // e.stopPropagation();
      // setPdfViewPath(utils.site.resourcePath(link))
    }
  }

  return (
    <div className="tippingText htmlPanel" dangerouslySetInnerHTML={{ __html: text }} onClick={(e) => handleClick(e)}></div>
  );
}

import React from "react";
import env from "../../../env";
import { usePost } from "../../../components";
import { useForm } from "../../../form";
import { Button, ErrorMessage } from "../../../components";
import { getContactModel } from "./contactFormModel";
import { gtm } from "../../../lib/tracking";

export function Contact(props) {
  const emailReceiver = props.emailReceiver;
  const model = getContactModel({});
  const form = useForm(model, { usePlaceholder: true });
  const [sendingStatus, setSendingStatus] = React.useState(null);
  const post = usePost();

  function onSubmit(e) {
    form.validateForm(e, () => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute("6LfUxxEqAAAAABuYhqccSJLsxrDZS-WPrAksq3u3", {
            action: "submit",
          })
          .then((token) => {
            const recaptchaValue = token;
            const value = {
              ...form.getValue(),
              emailReceiver: emailReceiver,
              recaptchaValue: recaptchaValue,
            };

            post.send(env.apiBase + "/api/contact/send", value);
            setSendingStatus("pending");
          });
      });
    });
    console.log("submitting");
  }

  // if (status === 'done') {
  //   //navigate(`/task/view/${taskId}`)
  //   window.location.reload();
  // }

  if (post.done() && sendingStatus === "pending") {
    var value = form.getValue();
    gtm.sendEvent("contactFormSubmitted", { contactEmail: value.email });
    form.reset(model);
    setSendingStatus(null);
  }

  const render = (name) => form.renderControl(name, null);

  return (
    <form className="contactForm">
      <div className="form-row">
        <div className="col-md-4 mb-2">{render("name")}</div>
        <div className="col-md-4 mb-2">{render("phone")}</div>
        <div className="col-md-4 mb-2">{render("email")}</div>
      </div>
      <div className="form-row">
        <div className="col-md-12 mb-2">{render("enquiry")}</div>
      </div>
      <div className="form-row">
        <div className="col-md-12 mb-2">
          <div className="actions">
            <Button onClick={onSubmit} status={post.status}>
              SEND MESSAGE
            </Button>
            <ErrorMessage errors={post.errors} />
            {post.done() && (
              <span style={{ color: "white", marginLeft: "1rem" }}>
                Successfully Sent!
              </span>
            )}
          </div>
        </div>
      </div>
    </form>
  );
}

import { fb, validators } from '../../../lib/form';

export function getModel(data) {
  const model = fb.group({
    username: [data.username || '', [validators.Required()], { label: 'Username', type: 'text' }],
    password: [data.password || '', [validators.Required()], { label: 'Password', type: 'password' }],
    
  });

  return model;
}
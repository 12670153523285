import { fb, validators } from '../../../lib/form';

export function getModel(data) {
  const model = fb.group({
    currentPassword: [data.currentPassword || '', [validators.Required()], { label: 'Current Password', type: 'password' }],
    password: [data.password || '', [validators.Required(), validators.Length('Min 7 characters', {min: 7})], { label: 'New Password', type: 'password' }],
    confirmPassword: [data.confirmPassword || '', [validators.Required()], { label: 'Confirm Password', type: 'password' }],
  });

  return model;
}
const comps = [
  {compId: 11, compCode: 'CS.individual', compName: 'CentreState Individual', compPath:'/tipping/individual' },
  {compId: 12, compCode: 'CS.sportingclub', compName: 'CentreState Sportingclub', compPath:'/tipping/sportingclub' },
  {compId: 13, compCode: 'CS.corvel', compName: 'CentreState Gazebo', compPath:'/tipping/gazebo' },
  {compId: 14, compCode: 'CS.wanderers', compName: 'CentreState Wanderers', compPath:'/tipping/wanderers' },
]


export function getComp(compId) {
  const comp = comps.filter(x => x.compId === compId);
  return comp[0];
}

export function setSession(sessionToken) {
  localStorage.setItem('tippingSession', sessionToken);
}

export function getSession(sessionToken) {
  return localStorage.getItem('tippingSession');
}

export function setTippingCompId(sessionToken) {
  localStorage.setItem('tippingCompId', sessionToken);
}

export function getTippingCompId(sessionToken) {
  return localStorage.getItem('tippingCompId');
}
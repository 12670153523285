import React from 'react';
import env from '../../../env';
import { useForm } from '../../../form';
// import { SiteLink} from '../../components';
import { usePost } from '../../../components';
import { Button, ErrorMessage } from '../../../components';
import { getModel } from './formModel';
import { useRouter } from '../../../components';
import { setSession, setTippingCompId } from '../tippingCommon';

export function TippingSignup({ compId }) {

  const { Redirect } = useRouter();
  const model = getModel({compId: compId});
  const form = useForm(model);
  const [sendingStatus, setSendingStatus] = React.useState(null);
  const post = usePost();

  function onSubmit(e) {
    form.validateForm(e, () => {
      const value = { ...form.getValue(), compId: compId };
      console.log(value);
      post.send(env.apiBase + "/api/tipping/signup", value);
      setSendingStatus('pending')
    })
    console.log('submitting')
  }

  if (post.done() && sendingStatus === 'pending') {
    const data = post.response;
    console.log(data, compId);
    setSession(data.results.response.sessionToken);
    setTippingCompId(compId);
    return <Redirect to={'/tipping/enterTip'} />
  }

  const render = (name) => form.renderControl(name, null);

  return (
    <form className="editor">
      <h4 style={{ margin: '1rem 0' }}>Account</h4>
      <div className="row">
        <div className="col-md-6">{render('username')}
          <div className="help">Only letters and/or numbers may be used in a username.(Minimum 3 characters, no spaces)</div></div>
        <div className="col-md-6">{render('email')}</div>
      </div>
      <div className="row">
        <div className="col-md-6">{render('password')}
          <div className="help">Minimum 7 characters</div>
        </div>
        <div className="col-md-6">{render('confirmPassword')}</div>
      </div>
      <h4 style={{ margin: '1rem 0' }}>Profile</h4>
      <div className="row">
        <div className="col-md-6">{render('firstName')}</div>
        <div className="col-md-6">{render('surname')}</div>
      </div>
      {compId !== 14 && <div>
        <div className="row">
          <div className="col-md-6">{render('address')}{render('address2')}</div>
          <div className="col-md-3">{render('suburb')}</div>
          <div className="col-md-3">{render('state')}</div>
        </div>
        <div className="row">
          <div className="col-md-6">{render('phone')}</div>
          <div className="col-md-6">{render('mobile')}</div>
        </div>
      </div>}

      <h4 style={{ margin: '1rem 0' }}>Tipping</h4>
      <div className="row">
        <div className="col-md-6">{render('favoriteTeam')}</div>
        {compId !== 14 && <div className="col-md-6">{render('favoriteLocalTeam')}</div>}
      </div>
      {compId === 11 && <div>
        <div className="row">
          <div className="col-md-6">{render('tradingName')}
            <div className="help">Trading name you use when dealing with Centre State Exports.</div>
          </div>
          <div className="col-md-6">{render('ngrNumber')}
            <div className="help">Max 4 people using one number.</div></div>
        </div>
      </div>}
      {compId === 12 && <div>
        <div className="row">
          <div className="col-md-6">{render('nominatingFarmer')}
            <div className="help">Name of farmer nominating the team</div>
          </div>
          <div className="col-md-6">{render('ngrNumber')}
            <div className="help">NGR number of farmer nominating the team</div></div>
        </div>
        <div className="row">
          <div className="col-md-6">{render('teamName')}
            <div className="help">
              Name of team you represent</div>
          </div>
          <div className="col-md-6">{render('abn')}
            <div className="help">Certificate of Incorporation / ABN number of Club</div></div>
        </div>
      </div>}
      <div className="form-row">
        <div className="col-md-12 mb-2">
          <div className="actions">
            <Button onClick={onSubmit} status={post.status}>SIGN UP</Button>
            <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
            <ErrorMessage errors={post.errors} />
          </div>
        </div>
      </div>
    </form>
  );
}
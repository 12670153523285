import React from 'react';
import env from '../../../env';
import { usePost } from '../../../components';
import { Loading, ErrorPopup } from '../../../components';
import { getSession } from '../tippingCommon';
import { Round } from './round';
import utils from '../../../utils';
import { TippingMenu } from '../menu';

export function EnterTip({ compId }) {
  
  const [currentRoundId, setCurrentRoundId] = React.useState(0);
  const [compRound, setCompRound] = React.useState(null);
  const [sendingStatus, setSendingStatus] = React.useState(null);
  const post = usePost();

  const sessionKey = getSession();

  React.useEffect(() => {
    //console.log('useEffect',currentRoundId)
    post.send(env.apiBase + "/api/tipping/gettips", {sessionToken: sessionKey, compRoundId: currentRoundId});
    setSendingStatus('pending')
    // eslint-disable-next-line
  }, [currentRoundId]);

  if (post.loading()) {
    return <Loading />
  } else if (post.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={post.errors} />
  }

  if (post.done() && sendingStatus === 'pending') {
    setSendingStatus(null)
    const data = post.response;
    setCompRound(data.results.response.compRound);
  }

  function roundChanged(roundId) {
    setCurrentRoundId(roundId)
  }

  function valueChanged(teamId_, gameId) {
    const teamId = parseInt(teamId_)
    //console.log(teamId, gameId)

    const game = utils.array.find(compRound.games, x => x.gameId === gameId)
    game.winTeamId = teamId;
    setCompRound({...compRound});
  }

  return (
   <div>
     <TippingMenu current="enterTip"/>
     <Round compRound={compRound} roundChanged={roundChanged} valueChanged={valueChanged}/>
   </div>
  );
}

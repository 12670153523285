import React from 'react';

export function Button({onClick, status, children, btnStyle='primary', type='button'}) {
  let buttonStyle = 'btn-' + btnStyle;
  return (
    <button className={`btn ${buttonStyle}`} onClick={onClick} type={type} disabled={status === 'pending'}>
      {status === 'pending' && <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>}
      {children}
    </button>
  );
}
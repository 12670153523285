/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './roundStyle';
import { Fragment } from 'react';
import env from '../../../env';
import { usePost } from '../../../components';
import { Button, ErrorMessage } from '../../../components';
import { getSession } from '../tippingCommon';

export function Round({ compRound, roundChanged, valueChanged }) {
  const post = usePost();

  const sessionKey = getSession();
  console.log('compRound', compRound)
  const days = Array.from(new Set(compRound.games.map(x => x.gameDate)))
  //console.log(days)

  function tip(e, gameId) {
    //console.log(e.target.value);
    valueChanged(e.target.value, gameId)
  }

  
  function onSubmit(e) {
    console.log(compRound);

    const value = {
      compRoundId: compRound.compRoundId,
      games: compRound.games.map(x => { return { gameId: x.gameId, marginVal: x.marginVal, winTeamId: x.winTeamId } })
    }
    post.send(env.apiBase + "/api/tipping/saveTips", {sessionToken: sessionKey, compRound: value});
  }

  const teamIcon = path => path.replace('/core/jabatippingclient_3_0/img/teamicon', '/dist/tipping/teamicon').replace('.jpg', '.png')

  return (
    <Fragment>
      <div css={style.roundHeader}>
        {compRound.roundHeaders.map(x => <RoundHeader key={x.compRoundId}
          roundChanged={roundChanged}
          round={x} currentRoundId={compRound.compRoundId} />)}
      </div>
      {days.map((day, index) => {
        const games = compRound.games.filter(x => x.gameDate === day);
        //console.log(games)
        return <div key={index}>
          <div css={style.day}>{day}</div>
          {games.map(game => <div key={game.gameId} css={style.game}>
            <div css={style.venue}>VENUE: {game.venue}, {game.gameTime} {game.gameId >= 1361 ? "(ACST)" : "(ACDT)"} </div>
            <div css={style.teams}>
              <div css={style.home}>
                <div css={style.homeIcon}><img src={teamIcon(game.homeTeamImage)} alt={game.homeTeamName} /></div>
                <div css={style.homeInput}>
                  <label css={style.teamName}>
                    <input type="radio" name={`game_${game.gameId}`} value={game.homeTeamId}
                      onChange={(e) => tip(e, game.gameId)}
                      disabled={game.finished}
                      checked={game.winTeamId === game.homeTeamId}></input>
                    {game.homeTeamName} <span css={style.teamHelp}>| HOME</span>
                  </label>
                </div>
              </div>
              <div css={style.away}>
                <div css={style.awayIcon}><img src={teamIcon(game.awayTeamImage)} alt={game.awayTeamName} /></div>
                <div css={style.awayInput}>
                  <label css={style.teamName}>
                    <input type="radio" name={`game_${game.gameId}`} value={game.awayTeamId}
                      onChange={(e) => tip(e, game.gameId)}
                      disabled={game.finished}
                      checked={game.winTeamId === game.awayTeamId}></input>
                    {game.awayTeamName} <span css={style.teamHelp}>| AWAY</span>
                  </label>
                </div>
              </div>

              <div css={style.draw}>
                <div css={style.drawInput}>
                  <label css={style.teamName}>
                    <input type="radio" name={`game_${game.gameId}`} value={0}
                      onChange={(e) => tip(e, game.gameId)}
                      disabled={game.finished}
                      checked={game.winTeamId === 0}></input>
                    <span css={[style.teamHelp, { display: 'inline', marginLeft: 0}]}>DRAW</span>
                  </label>
                </div>
              </div>
            </div>
          </div>)}

        </div>
      })}

      {!compRound.viewMode && 
        <div className="row">
          <div className="col-md-12 mb-2">
            <div className="actions">
              <Button onClick={onSubmit} status={post.status}>SAVE</Button>
              <ErrorMessage errors={post.errors} />
              {post.done() && <span style={{ color: 'chocolate', marginLeft: '1rem' }}>Successfully Saved!</span>}
            </div>
          </div>
        </div>
      }
    </Fragment>
  );
}

function RoundHeader({ round, currentRoundId, roundChanged }) {
  const current = round.compRoundId === currentRoundId ? style.roundHeaderCellCurrent : null;
  return <div css={[style.roundHeaderCell, current]} onClick={() => roundChanged(round.compRoundId)}>
    {round.roundName === '0' ? "OR" : round.roundName}
  </div>
}
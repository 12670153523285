/** @jsx jsx */
import { jsx } from '@emotion/core'
//import style from './style';
//src="https://centrestate.stg4.jaba.com.au/sites/centrestate/media/pdf/growerupdateformwithtceft.pdf">
export function PdfViewer(props){
    return(
        <div style={{paddingTop:'3rem'}}>
            <iframe width="100%" frameBorder="0" style={{height:'81vh'}} title="viewer"
                src="/sites/centrestate/media/pdf/growerupdateformwithtceft.pdf">
             </iframe>
        </div>
    )
}
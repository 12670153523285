import React from 'react';
import env from '../../../env';
import { usePost } from '../../../components';
import { Loading, ErrorPopup } from '../../../components';
import { getSession } from '../tippingCommon';
import { TippingMenu } from '../menu';
import './review.scss';

export function ReviewStanding({compCode}) {
  
  const [sendingStatus, setSendingStatus] = React.useState(null);
  const [standing, setStanding] = React.useState('');
  const post = usePost();

  const sessionKey = getSession();

  React.useEffect(() => {
    //console.log('useEffect',currentRoundId)
    post.send(env.apiBase + "/api/tipping/getStanding", {sessionToken: sessionKey, compCode: compCode});
    setSendingStatus('pending')
    // eslint-disable-next-line
  }, []);

  if (post.loading()) {
    return <Loading />
  } else if (post.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={post.errors} />
  }

  if (post.done() && sendingStatus === 'pending') {
    setSendingStatus(null)
    const data = post.response;
    setStanding(data.results.response.rank);
  }

  return (
   <div>
     {!compCode && <TippingMenu current="standing"/>}
     <div className="tippingStanding" dangerouslySetInnerHTML={{ __html: standing }}></div>
   </div>
  );
}

//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

import { css } from '@emotion/core'
import { mq } from '../../cssInJs'


export default {
    pageTiles : css({
         display: 'flex',
         justifyContent: 'flex-start',
         flexWrap: 'wrap',
         margin: '2rem -10px'
    }),
    pageTile__tile : css(mq({
         flex: '0 0 100%',
         maxWidth: '100%',
         padding: '10px'
    })),

    tile__content : css({
         display: 'flex',
         flexDirection: 'row',
         justifyContent: 'space-between',
         width: '100%',
         height: '100%',
         cursor: 'pointer',
         position: 'relative',
         flexWrap: 'wrap',
         '&:hover' : {
            textDecoration: 'none',
            '& .pageTile__bsyc__content__details' : {
                backgroundColor : '#429b57',
                color: 'white',
            }
         }
    }),

    tile__bg : css(mq({
         height: '0',
         paddingTop: '30%',
         backgroundPosition: '50%',
         backgroundRepeat: 'no-repeat',
         backgroundSize: 'cover',
         flex: '1 1 300px',
    })),

    tile__details : css({
         overflow: 'hidden',
         flexGrow: '1',
         padding: '1.5rem',
         textAlign: 'center',
         width: '100%',
         bottom: '0',
         backgroundColor: 'rgba(255,255,255,.5)',
         //backgroundColor : '#77cf22',
         flex: '1 1 300px',
         transition: 'all .2s ease-in-out',
         color: '#3d3d3d',
         '& .pageTile__bsyc__container' : {
            position: 'relative',
            top: '44%',
            transform: 'translateY(-50%)',
            '& .pageTile__bsyc__title' : {
                fontFamily: 'proxima-soft, sans-serif',
                fontSize: '2rem',
                //textTransform: 'uppercase',
                fontWeight: '700',
                lineHeight: '1',
                margin: '0',
                paddingTop: '.5rem',
                paddingBottom: '.5rem',
                color:'inherit'
            },
            '& .pageTile__bsyc__desc' : {
                lineHeight: '1.3',
                fontSize: '.9rem',
                //color: '#3d3d3d',
                minHeight: '70px',
                color:'inherit'
             }
         }
    })
}
import variables from './cssInJs/variables';

const cssEmotion = {
  breakpointNames: variables.breakpointNames,
  breakpoints: variables.breakpoints
}

const iconColors = {
  primary: variables.primaryColor,
  secondary: variables.secondaryColor || '#000'
}

export default {
  cssEmotion,
  iconColors
}
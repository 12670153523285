/** @jsx jsx */
import { jsx, css } from '@emotion/core'
//import React from 'react';
import { SiteLink } from '../../components';
import { setSession } from './tippingCommon';
import { useRouter } from '../../components';


const style = {
  tippingMenu: css({
    margin: '1.5rem 0',
    'a': {
      marginRight: '0.5rem',
      paddingRight: '0.5rem',
      borderRight: 'solid 1px #bbb',
      '&:last-of-type': {
        border: 'none'
      }
    }
  }),
  current: css({
    fontWeight: 'bold',
  })
} 

export function TippingMenu({current}) {
  const { navigate } = useRouter();

  function logout(e){
    e.preventDefault();
    setSession(null);
    navigate('/footy-tips')
  }

  return (
    <div css={style.tippingMenu}>
      <SiteLink css={current === 'enterTip' && style.current} to="/tipping/enterTip">ENTER TIP</SiteLink>
      <SiteLink css={current === 'standing' && style.current} to="/tipping/standing">REVIEW STANDING</SiteLink>
      {/* <SiteLink css={current === 'profile' && style.current} to="/tipping/profile">PROFILE</SiteLink> */}
      <SiteLink css={current === 'changePassword' && style.current} to="/tipping/change-password">RESET PASSWORD</SiteLink>
      <a href="/" onClick={logout}>LOGOUT</a>
    </div>
  );
}
import React from 'react';
import env from '../../../env';
import { useForm } from '../../../form';
import { SiteLink } from '../../../components';
import { usePost } from '../../../components';
import { Button, ErrorMessage } from '../../../components';
import { getModel } from './formModel';
import { useRouter } from '../../../components';

export function TippingRecoverPassword() {

  // from query
  const { query } = useRouter();
  const compId = query.comp || '';

  const model = getModel({});
  const form = useForm(model);
  const [sendingStatus, setSendingStatus] = React.useState(null);
  const post = usePost();

  if (!compId) {
    return <div>
      <p>Sorry, we can't identify the competition. Please use link in the login pages. </p>
      <p><SiteLink to="/footy-tips">Go back to the tipping main.</SiteLink></p>
    </div>
  }

  function onSubmit(e) {
    form.validateForm(e, () => {
      const value = { ...form.getValue(), compId: compId };
      console.log(value);
      post.send(env.apiBase + "/api/tipping/recoverPassword", value);
      setSendingStatus('pending')
    })
    console.log('submitting')
  }

  if (post.done() && sendingStatus === 'pending') {
    form.reset(model)
    setSendingStatus(null)
  }

  const render = (name) => form.renderControl(name, null);

  return (
    <div style={{ maxWidth: '960px', margin: 'auto' }}>
      <h1>Password Recovery</h1>
      <p>Forgot Your Password?</p>
      <p>Enter your User Name to receive your password by email.</p>

      <form className="editor">
        <div className="row">
          <div className="col-md-6">{render('username')}</div>
        </div>
        <div className="row">
          <div className="col-md-12 mb-2">
            <div className="actions">
              <Button onClick={onSubmit} type="submit" status={post.status}>SEND</Button>
              <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
              <ErrorMessage errors={post.errors} />
              {post.done() && <span style={{ color: 'chocolate', marginLeft: '1rem' }}>Successfully sent! Check your email box.</span>}
            </div>
          </div>
        </div>

      </form>
    </div>
  );
}
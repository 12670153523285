import { fb, validators } from '../../lib/form';

export function getModel(data) {

  const model = fb.group({
    ngrNumber: [data.ngrNumber || '', [validators.Required()], { label: 'NGR number', type: 'text' }],
    tradingName: [data.tradingName || '', [validators.Required()], { label: 'Trading name', type: 'text' }],
    contactPerson: [data.contactPerson || '', [validators.Required()], { label: 'Contact name', type: 'text' }],
    contractNumber: [data.contractNumber || '', [], { label: 'Contract number', type: 'text' }],
    contractDate: [data.contractDate || '', [], { label: 'Contract date', type: 'text' }],
    grade: [data.grade  || '', [], { label: 'Grade', type: 'text' }],
    tonnes: [data.tonnes  || '', [], { label: 'Tonnes', type: 'text' }],
    email: [data.email || '', [validators.Required(), validators.Email()], { label: 'Email address', type: 'email' }],
  });

  return model;
}
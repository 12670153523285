import { fb, validators } from '../../lib/form';

export function getModel(data) {
  const subscribeList = [
    'Subscribe', 'Un-Subscribe'
  ].map(x => { return { name: x, value: x } })

  const model = fb.group({
    ngrNumber: [data.ngrNumber || '', [validators.Required()], { label: 'NGR number', type: 'text' }],
    tradingName: [data.tradingName || '', [validators.Required()], { label: 'Trading name', type: 'text' }],
    contactPerson: [data.contactPerson || '', [validators.Required()], { label: 'Contact name', type: 'text' }],
    mobile: [data.mobile || '', [validators.Required()], { label: 'Mobile number', type: 'text' }],
    email: [data.email || '', [validators.Required(), validators.Email()], { label: 'Email address', type: 'email' }],
 
    smsService: [data.smsService || 'Subscribe', [], { label: 'SMS Service', type: 'radioList', options: subscribeList, style: { inline: true } }],
    emailService: [data.emailService || 'Subscribe', [], { label: 'Email Service', type: 'radioList', options: subscribeList, style: { inline: true } }],
    
  });

  return model;
}

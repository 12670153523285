import React from 'react';
import env from '../../../env';
import { useForm } from '../../../form';
import { SiteLink} from '../../../components';
import { usePost } from '../../../components';
import { Button, ErrorMessage } from '../../../components';
import { getModel } from './formModel';
import { useRouter } from '../../../components';
import { setSession, setTippingCompId } from '../tippingCommon';

export function TippingLogin({ compId }) {

  const { Redirect } = useRouter();
  const model = getModel({});
  const form = useForm(model);
  const [sendingStatus, setSendingStatus] = React.useState(null);
  const post = usePost();

  function onSubmit(e) {
    form.validateForm(e, () => {
      const value = { ...form.getValue(), compId: compId  };
      //console.log(value);
      post.send(env.apiBase + "/api/tipping/login", value);
      setSendingStatus('pending')
    })
    console.log('submitting')
  }

  if (post.done() && sendingStatus === 'pending') {
    // form.reset(model)
    // setSendingStatus(null)
    
    const data = post.response;
    console.log(data, compId);
    setSession(data.results.response.sessionToken);
    setTippingCompId(compId);
    return <Redirect to={'/tipping/enterTip'} />
  }

  const render = (name) => form.renderControl(name, null);

  return (
    <form className="editor" style={{maxWidth:'960px', margin: 'auto'}}>
      <div className="row">
        <div className="col-md-6">{render('username')}</div>
      </div>
      <div className="row">
        <div className="col-md-6">{render('password')}</div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-2">
          <div className="actions">
            <Button onClick={onSubmit} type="submit" status={post.status}>LOG IN</Button>
            <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
            <ErrorMessage errors={post.errors} />
            {post.status === 'done' && 'Successfully sent!'}
          </div>
        </div>
      </div>
      <div>
      <SiteLink style={{marginBottom: 3}} to={`/tipping/password-recovery?comp=${compId}`}>Forgot your password?</SiteLink>
      </div>
      <div>
      Are you having trouble logging in? contact us now  
      {compId === 14?
      <a style={{marginLeft: 10}} href="mailto:roger@centrestateexports.com.au">roger@centrestateexports.com.au</a>:
      <a style={{marginLeft: 10}} href="mailto:footytips@centrestateexports.com.au">footytips@centrestateexports.com.au</a>
      }
      </div>
    </form>
  );
}
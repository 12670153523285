import React from 'react';
import cmsUtils from '../../utils/cmsUtils';
import utils from '../../../utils';
import { SearchResult } from '../../../site/searchResult/searchResult';
import { InstagramFeed } from '../../../components';
import { AppVersion } from '../../../components';
import { TippingSignup } from '../../../site/tipping/signup/signup';
import { TippingLogin } from '../../../site/tipping/login/login';
import { TippingRecoverPassword } from '../../../site/tipping/recoverPassword/recoverPassword';
import { EnterTip } from '../../../site/tipping/enter/enterTip';
import { ReviewStanding } from '../../../site/tipping/review/reviewStanding';
import { TippingChangePassword } from '../../../site/tipping/password/changePassword';
import { TippingText } from '../../../site/tipping/text/tippingText';
import { PriceUpdate } from '../../../site/priceUpdate/priceUpdate';
import { PriceUpdateSimple } from '../../../site/priceUpdateSimple/priceUpdate';
import { PaymentPaperwork } from '../../../site/paymentPaperwork/form';
import { ContractPaperwork } from '../../../site/contractPaperwork/form';


export function Code(props) {
  const item = props.item;
  //const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};

  //console.log('item', item, cmsOption);

  const code = cmsUtils.payload(item, 'Code');
  const codeType = cmsUtils.payload(item, 'CodeType');
  let controls = null;
  if (codeType === 'Json') {
    const codeJson = JSON.parse(code);

    if (codeJson.control === 'search-results'){
      controls = <SearchResult />
    } else if(codeJson.control === 'app'){
      controls = <AppVersion />
    } else if (codeJson.control === 'instagram') {
      controls = <InstagramFeed />
    } else if (codeJson.control === 'signup-individual') {
      controls = <TippingSignup compId={11} />
    } else if (codeJson.control === 'login-individual') {
      controls = <TippingLogin compId={11} />
    } else if (codeJson.control === 'signup-sportingclub') {
      controls = <TippingSignup compId={12} />
    } else if (codeJson.control === 'login-sportingclub') {
      controls = <TippingLogin compId={12} />
    } else if (codeJson.control === 'signup-gazebo') {
      controls = <TippingSignup compId={13} />
    } else if (codeJson.control === 'login-gazebo') {
      controls = <TippingLogin compId={13} />
    } else if (codeJson.control === 'signup-wanderers') {
      controls = <TippingSignup compId={14} />
    } else if (codeJson.control === 'login-wanderers') {
      controls = <TippingLogin compId={14} />
    } else if (codeJson.control === 'entertip') {
      controls = <EnterTip />
    } else if (codeJson.control === 'standing') {
      controls = <ReviewStanding compCode={codeJson.compCode} />
    } else if (codeJson.control === 'change-password') {
      controls = <TippingChangePassword />
    } else if (codeJson.control === 'password-recovery') {
      controls = <TippingRecoverPassword />
    } else if (codeJson.control === 'tippingText') {
      controls = <TippingText compCode={codeJson.compCode} textType={codeJson.textType} />
    } else if (codeJson.control === 'price-update') {
      controls = <PriceUpdate />
    } else if (codeJson.control === 'price-update-simple') {
      controls = <PriceUpdateSimple />
    } else if (codeJson.control === 'payment-paperwork') {
      controls = <PaymentPaperwork />
    } else if (codeJson.control === 'contract-paperwork') {
      controls = <ContractPaperwork />
    }
  }

  const cssClass = utils.classNames('cms_item', item.cssClass || item.anchorName || '');

  //let itemElt = (<div dangerouslySetInnerHTML={{ __html: content }}></div>);

  if (cmsOption.isCmsEdit)
    return (
      <div className={cssClass}
        data-cms-item-id={item.itemId} 
        id={item.anchorName}>
<pre style={{fontSize: '12px', margin:'5px', padding: '10px', background:'#eee', border: '1px solid #ccc'}}>
<b>Code Type: {codeType}</b>
{code}</pre>          
        </div>
    )
  else if (codeType === 'Text')
    return (
      <div className={cssClass} id={item.anchorName}
        dangerouslySetInnerHTML={{ __html: code }}></div>
    )
  else
    return (
      <div className={cssClass} id={item.anchorName}>{controls}</div>
    )
}
